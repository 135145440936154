import TasqJob from '@/interfaces/tasqs/TasqJob';


const mapDefaultProducingTasq = (t, userEmail = ''): TasqJob => {
  let wellName = t.wellName != null ? t.wellName : t.NodeID === 'Example Job' ? 'Braum Family 9-4-1XH' : t.NodeID;
  if (t.wellName != null) {
    wellName = t.wellName;
  } else {
    wellName = t.NodeID;
    if (t.NodeID.wellName != null) {
      wellName = t.NodeID.wellName;
    }
  }

  const tasq = {
    id: t.PredictionID || t.ID || t.NodeID || t.wellName,
    scheduledJobID: t.ID != null ? t.ID : null,
    predictionType: 'Producing',
    engineerType: 'Producing',
    wellName,
    padName: t.PadName,
    area: t.Area,
    route: t.Route,
    comments: t.comments,
    wells: [],
    team: t.Team,
    // eslint-disable-next-line no-nested-ternary
    level: t.Level != null ? t.Level : t.level != null ? t.level : 'WELL',

  };
  
  // @ts-ignore
  return tasq;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  mapDefaultProducingTasq,
};
