import accountModule from '@/store/modules/accountModule';
import Component from 'vue-class-component';
import Vue from 'vue';
import metaDataModule from '@/store/modules/metaDataModule';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';

@Component
export default class GenericMixin extends Vue {
  get viewPortHeight() {
    return {
      '--height': `${accountModule.viewPortClientHeight}px`,
    };
  }

  get viewPortHeightValue() {
    return accountModule.viewPortClientHeight;
  }

  get viewPortZoom() {
    return accountModule.viewPortZoom;
  }

  get sidebarCondition() {
    const currentRoute: any = this.$route.name;
    return ['Tasqs', 'UserSettings',
    'WorkTicketCalendar', 'Pads', 'Wells',
     'WorkTickets', 'ControlPanel','FormBuilder'].includes(currentRoute);
  }


  convertVhToPx(vh) {
    return `${accountModule.viewPortClientHeight * (vh / 100)}px`;
  }

  get canvasZoomLevel() {
    if (accountModule.viewPortZoom === 0.75) {
      return 1.33;
    }
    if (accountModule.viewPortZoom === 0.55) {
      return 1.82;
    }
    return 1;
  }



  get isVerticalListUnPinned() {
    return tasqsListModule.isVerticalListUnPinned && (tasqsListModule.activeTasq || assetsModule.activeTasq);
  }

  get isVerticalListHovering() {
    return tasqsListModule.isVerticalListHovering;
  }


  get showEpandedView(){
    return !this.isVerticalListUnPinned || this.isVerticalListHovering
  }



  // global Datalist functions


  async fetchDatalistOptions(requiredDataList: any, title: string, setFunctionName: string) {
    const requiredDatalistItem = requiredDataList.find((list) => list.title === title);
    if (requiredDatalistItem) {
      const requiredItemOptions = await metaDataModule.getDatalist(requiredDatalistItem.id);
      if (requiredItemOptions && requiredItemOptions.length && requiredItemOptions[0].ListItems) {
        const options = requiredItemOptions[0].ListItems.filter((field) => field.Active)
          .map((item) => item.Name)
          .sort();

        if (options) {
          metaDataModule[setFunctionName](options);
        }
      }
    }
  }

  async setRequiredDatalistOptions() {
    if (!metaDataModule.requiredDatalist.length) {
      await metaDataModule.getDatalist();
    }
    const requiredDataList = metaDataModule.requiredDatalist;

    const bulkPromises: any = [];

    bulkPromises.push(
      this.fetchDatalistOptions(requiredDataList, 'Waiting On', 'setWaitingOnList'),
    );
    bulkPromises.push(
      this.fetchDatalistOptions(requiredDataList, 'Blocked Reasons', 'setBlockedReasonList'),
    );
    Promise.all(bulkPromises)
      .then(async () => {})
      .catch((err) => {
        this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
          text: 'Unable to fetch required list options',
          error: true,
        });
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }
}
